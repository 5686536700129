<script>
import AlternativeRoute from './AlternativeRoute.vue';

export default {
  name: 'RateListHeader',

  components: {
    AlternativeRoute
  },
  
  props: {
    rate_list_length: {
      type: Number,
      default: 0
    },
    
    showRecommends: {
      type: Boolean,
      default: false,
    },

    activeFilter: {
      type: Boolean,
      default: false,
    },

    showAlternativeRoute: {
      type: Boolean,
      default: false,
    }
  },

  data() {
    return {
      filterIcon: require('../../assets/img/icons/filter.svg'),

      tooltipVisible: false,
    }
  },

  computed: {
    windowSize() {
      return document.documentElement.clientWidth;
    },
  },
  
  methods: {
    hideTooltip() {
      this.timeoutId = setTimeout(() => {
        this.$emit('showTooltip', false);
      }, 3000);
    },

    openFilter() {
      this.$emit('openFilter', true);
    }
  }
}
</script>

<template>
  <div class="list-header">
    <div class="list-header__filter">
      <template v-if="showRecommends">
        <v-tooltip right color="black" transition="slide-y-reverse-transition" class="rate-info-logos__tooltip">
          <template v-slot:activator="{ on, attrs }">
            <button v-bind="attrs" v-on="on" class="list-header__filter-btn list-header__filter-btn-disable" :disabled="true">
              <img class="iconClass" :src="filterIcon" alt="filterIcon">
            </button>
          </template>
          <span class="mt-2">Фильтр недоступен в режиме<br> <b>"Vedexx рекомендует"</b></span>
        </v-tooltip>
      </template>
      <template v-else>
        <button class="list-header__filter-btn" @click="openFilter">
          <v-badge dot color="var(--green-60)" :value="activeFilter">
            <img class="iconClass" :src="filterIcon" alt="filterIcon">
          </v-badge>
        </button>
      </template>

      <span class="list-header__filter-count" v-if="rate_list_length != 0 && !showRecommends && windowSize > 744">Найдено {{rate_list_length}}</span>
    </div>
    <AlternativeRoute class="list-header__alt-routes" :class="showAlternativeRoute ? 'show-alt-routes' : '' " v-show="showAlternativeRoute"/>
    <div class="list-header__control">
      <button
        v-ripple
        class="list-header__control-btn"
        :class="showRecommends ? '' : 'list-header__control-btn-active'"
        @click="$emit('openRecommends', false)"
      >
        <span class="hide-mobile">Все предложения</span>
        <span class="hide-desktop">Все ставки</span>
      </button>
      
      <button
        v-ripple
        class="list-header__control-btn"
        :class="showRecommends ? 'list-header__control-btn-active' : ''"
        @click="$emit('openRecommends', true)"
      >
        Vedexx рекомендует
        <img 
          @mouseenter="$emit('showTooltip', true)"
          @mouseleave="hideTooltip" 
          v-if="!showRecommends" 
          src="../../assets/img/icons/mdi/mdi-alert-circle-outline.svg" alt="mdi-alert-circle-outline">
        <img 
          @mouseenter="$emit('showTooltip', true)"
          @mouseleave="hideTooltip" 
          v-else
          src="../../assets/img/icons/mdi/mdi-alert-circle-outline-white.svg" alt="mdi-alert-circle-outline">
      </button>
    </div>
  </div>
</template>

<style scoped lang="scss">
.list-header {
  overflow: hidden;
  align-items: center;
  width: 100%;
  display: flex;  
  justify-content: space-between;

  &:has(.show-alt-routes){
    display: grid;
    grid-template-columns: minmax(120px, max-content) minmax(300px, max-content) minmax(max-content, 450px);
    justify-content: space-between;

    @media screen and (max-width: 1440px){
      grid-template-columns: minmax(300px, max-content) minmax(120px, max-content);
      grid-template-rows: repeat(2, 1fr);
    }

    @media (max-width: $mobile-width) {
      grid-template-columns: 1.5fr 1fr;
    }

    .list-header__filter{
      margin-right: 24px;

      @media screen and (max-width: 1500px){
        margin-left: 18px;
        margin-right: 18px;
      }

      @media screen and (max-width: 1440px){
        grid-column: 2 / 3;
        grid-row: 2 / 3;
        margin-right: unset;
      }

      @media (max-width: $mobile-width) {
        grid-column: 3 / 3;
      }
    }

    .list-header__control{
      margin-left: 18px;
    
      @media screen and (max-width: 1440px){
        grid-column: 1 / 3;
        margin: 0 auto;
      }
      
      @media (max-width: $mobile-width) {
        grid-column: 1 / 4;
      }
    }
  }

  @media (max-width: $mobile-width) {
    flex-direction: column;
  }


  &__filter {
    display: flex;
    align-items: center;
    margin-left: 36px;

    @media (max-width: $mobile-width) {
      margin-left: 4px;
      width: 100%;
      margin-top: 10px;
      order: 2;
      left: 0;
      align-items: center;
      justify-content: flex-start;
      align-content: flex-start;
    }

    &-btn {
      margin-right: 18px;
      margin-top: 4px;
      cursor: pointer;

      &-badge {
        height: 6px;
        width: 6px;
      }

      &-disable {
        opacity: 0.4;
        cursor: not-allowed;
      }

      @media (max-width: $mobile-width) {
        fill: rgba(255, 255, 255, 0.50);
        stroke-width: 1px;
        border-radius: 50%;
        filter: drop-shadow(0px 1px 4px rgba(0, 0, 0, 0.10));
        backdrop-filter: blur(2px);
        border: 1px solid rgba(0, 0, 0, 0);
      }
    }

    &-count {
      font-size: 14px;
      font-weight: 500;
      line-height: 16px;
      letter-spacing: 0;
      text-align: right;
      color: var(--grey-70);
      margin-right: $size-8;

      @media (max-width: $mobile-width) {
        display: none;
      }
    }
  }

  &__control {
    background: var(--grey-20);
    border-radius: 30px;
    padding: 4px;

    display: flex;

    @media (max-width: $mobile-width) {
      width: 100%;
    }
    
    &-btn {
      padding: 10px 35px;
      font-size: 14px;
      font-weight: 600;
      line-height: 20px;
      letter-spacing: 0.1px;
      text-align: center;
      color: var(--grey-70);
      border-radius: 30px;
      background: var(--grey-20);
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 0 5px;
     

      @media (max-width: $mobile-width) {
        padding: 9px 18px; 
      }

      @media (max-width: 400px) {
        padding: 9px 5px 9px 4px; 
      }
      
      &:first-child {
        @media (max-width: $mobile-width) {
          width: 40%;
        }
      }
      
      &:last-child {
        @media (max-width: $mobile-width) {
          width: 60%;
        }
      }
      
      &-active {
        color: #FFFFFF;
        background: #305CA8;

        &:before {
          content: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'%3E%3Cpath fill='%23FFFFFF' d='M9 16.17L4.83 12l-1.42 1.41L9 19L21 7l-1.41-1.41z'/%3E%3C/svg%3E");
          color: #FFFFFF;
          width: 16px;
          height: 20px;
          display: inline-block;
          align-content: center;
          position: relative;
          top: 2px;
          margin-right: 8px;
          
          @media (max-width: $mobile-width) {
            content: none;
            margin: 0;
          }
        }
      }
    }
  }
}
</style>

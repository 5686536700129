<script>
import SliderTableRateCard from "./SliderTableRateCard.vue";
export default {
  name: 'RateTableSlide',
  
  components: {
    SliderTableRateCard
  },
  
  props: {
    slide: {
      required: true,
      type: [Array, Object]
    }
  },
  
  data() {
    return {
      displayedPortCount: 4,
      windowWidth: window.innerWidth,
      isOpenPort: false,
      message: 'посмотреть все',
    }
  },
  
  mounted() {
    window.addEventListener('resize', this.handleResize);
    this.handleResize()
    
    this.isOpenPort = false
    this.message = 'просмотреть все'
  },
  
  methods: {
    handleResize() {
      this.windowWidth = window.innerWidth
      if (this.windowWidth < 1201 && this.windowWidth > 743) {
        this.displayedPortCount = 2
      } else if (this.windowWidth < 744) {
        this.displayedPortCount = 1
      } else {
        this.displayedPortCount = 4
      }
    },
    
    openPorts() {
      if (!this.isOpenPort) {
        this.displayedPortCount = 4
        this.isOpenPort = true
        this.message = 'скрыть'
        this.$emit('openSlide', {
          value: true,
          size: this.windowWidth < 744 ? '2200px' : '1300px'
        })
      } else {
        this.handleResize()
        this.isOpenPort = false
        this.message = 'просмотреть все'
        this.$emit('openSlide', {
          value: false,
          size: '870px'
        })
      }
    }
  },
}
</script>

<template>
  <div class="slide g-row g-container">
    <div
      class="slide__column g-col-md-3 g-col-sm-4"
      v-for="(port, index) in slide.ports.slice(0, displayedPortCount)"
      :key="index"
    >
      <div class="slide__column-ports-name">
        {{port.name}}
      </div>
      
      <SliderTableRateCard
        v-for="(rate, index) in slide.rates"
        :key="index"
        :port_from_id="port.id"
        :rate="rate"
        class="slide__column-card"
      />
    </div>
    
    <div
      v-if="windowWidth < 1201"
      class="slide__show-all"
      @click="openPorts"
    >
      {{message}}
    </div>
  </div>
</template>

<style lang="scss" scoped>
.slide {
  width: 100%;
  
  &__column {
    position: relative;
    flex: 1;
    
    &:not(:last-child) {
      @include right-gap;
    }
    
    @media (max-width: $tablet-width) {
      min-width: calc(50% - 32px);
    }
    
    @media (max-width: 743px) {
      min-width: calc(100% - 32px);
    }
    
    &-ports-name {

      @include font-title-1;
      
      font-weight: 800;
      font-size: 24px;
      line-height: 33px;
      letter-spacing: 0.01em;
      color: #FFFFFF;
      text-align: left;
      margin: $size-16 0 $size-8;
    }
    
    &-card {
      height: 120px;
    }
  }
  
  &__show-all {
    margin-top: $size-12;
    font-size: $size-12;
    display: flex;
    width: 100%;
    font-weight: 800;
    color: #FFFFFF;
    align-items: flex-end;
    justify-content: flex-end;
    text-align: center;
    line-height: 15px;
    cursor: pointer;
    z-index: 25;
    @include right-gap;
  }
}

</style>

<script>
import { bus } from "@/router/bus";
import {getCookie, setCookie} from "@/utils/cookie";
import {reachYMGoal} from "@/utils/metrika";
export default {
  name: 'BannerPreview',
  
  props: {
    banner: { type: Object, required: true },
    read: { type: Boolean }
  },

  data () {
    return {
      isClicked: false,
    }
  },

  methods: {
    openFullBanner() {
      bus.$emit('openFullBanner', this.banner)
    },

    openBannerLink(banner) {
      this.saveViewedBannerId(banner.id)
      this.isClicked = true;
      window.open(banner.properties.url, '_blank')
    },

    addOpenMetric() {
      switch (+this.banner.id) {
        case 1:
          this.addMetrics('sales.vedexx_open');
          break;
        case 2:
          this.addMetrics('sales.vedexx/conditions_open');
          break
        case 3:
          this.addMetrics('sales.vedexx/time_open');
          break;
      }
    },

    addMetrics(name) {
      reachYMGoal(this, name)
    },

    saveViewedBannerId(bannerId) {
      const viewedBannerIdsString = getCookie('viewedBannerIds');
      let viewedBannerIds = []
      if (viewedBannerIdsString) {
        viewedBannerIds = JSON.parse(viewedBannerIdsString);
      }
      if (!viewedBannerIds.includes(bannerId)) {
        viewedBannerIds.push(bannerId);
        setCookie('viewedBannerIds', JSON.stringify(viewedBannerIds), 1); // 1 день
      }
    },
  },
}
</script>

<template>
  <div class="banner-preview" :class="{ 'read' : read || isClicked }">
    <div class="banner-preview__header">
      <div class="banner-preview__header-avatar" v-if="banner.properties.icon.length > 0">
        <img
          :src="$api_content + banner.properties.icon"
          :alt="banner.properties.title"
          class="banner-preview__header-avatar-image"
        />
      </div>
      <div class="banner-preview__header-avatar" v-else></div>
      <div class="banner-preview__header-title">{{banner.properties.title}}</div>
    </div>
    <div class="banner-preview__body">{{banner.properties.preview_text}}</div>
    <div class="banner-preview__action">
      <button type="button" class="banner-preview__action-button" v-ripple @click="openBannerLink(banner), addOpenMetric()">Узнать подробнее</button>
    </div>
  </div>
</template>

<style scoped lang="scss">
.banner-preview {
  width: 100%;
  height: auto;
  padding: 16px 16px 24px;
  border-radius: 8px;
  background: var(--blue-10);
  
  &.read {
    background: none;
    border: 2px solid #ECF3FF;
  }
  
  &__header {
    display: flex;
    margin-bottom: 16px;
    gap: 16px;
    align-items: center;
    
    &-avatar {
      width: 32px;
      height: 32px;
      border-radius: 50%;
      background: #C2CEE3;

      &-image {
        width: 32px;
        height: 32px;
        border-radius: 50%;
        position: relative;
        object-fit: cover;
      }
    }
    
    &-title {
      font-family: 'Montserrat', sans-serif;
      font-size: 16px;
      font-weight: 700;
      line-height: 16px;
      color: var(--blue-70);
    }
  }
  
  &__body {
    font-family: 'Montserrat', sans-serif;
    font-size: 14px;
    font-weight: 400;
    line-height: 16px;
    text-align: left;
    color: var(--grey-70);
    margin-bottom: 16px;
    word-break: break-word;
  }
  
  &__action {
    display: flex;
    gap: 16px;
    align-items: center;
    justify-content: flex-end;
    
    &-button {
      font-family: 'Montserrat', sans-serif;
      font-size: 14px;
      font-weight: 700;
      line-height: 16px;
      color: var(--blue-70);
      padding: 8px 24px 8px 24px;
      border-radius: 32px;
      background: var(--blue-20);
      
      &-read {
        background: none;
        border: 1px solid var(--grey-30);
      }

      @media (max-width: $mobile-width){
        padding: 14px;
        width: 100%;
      }

    }
  }
}
</style>
